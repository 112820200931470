<template>
<v-card class="mx-auto d-flex flex-column rounded-md" max-width="500" min-width="250" min-height="300">
    <v-img class="dark--text align-end pt-5" max-height="100px" src="../assets/images/violet-wallpaper.jpg">
        <v-row class="px-4 mt-5" style="background: #F6F2FF;">
            <v-col cols="8" class="text-left pt-3 pb-0" style="white-space: nowrap;">
                <div style="font-size:140%;"> <b style="font-weight: bold;">Course No. :</b>
                    {{regCourseObject.courseNumber}}
                </div>
            </v-col>
          
            <v-col cols="4" class="text-right">
                <v-btn text icon color="lighten-2" @click="goToCourse(regCourseObject._id)">
                    <v-icon>mdi-application-export</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="px-4 my-0" style="height:60px; background: #F6F2FF;">
            <v-col cols="12" class="text-left pt-0 pb-0" style="font-size:14px;font-weight: bold;">
                <p class="mb-0">
                    Course Title : {{regCourseObject.title}}
                </p>
            </v-col>

        </v-row>
        <v-spacer></v-spacer>
    </v-img>
    <v-card-text class="text--primary pb-5">
        <v-row>
            <v-col class="p-1" max-height="20px">
                <div>
                    <v-btn v-for="(items,i) in regCourseObject.services" :key="i" class="ml-4 mt-4" color="#FFFFFF" max-width="82" elevation="1" @click="clickShowListItems(items.service, regCourseObject._id)">
                        <v-img alt="service-logo" :src="getServiceImage(items.service)" max-height="60" max-width="60">
                        </v-img>
                    </v-btn>
                    <v-menu rounded="md" offset-y v-if="currentSemester === regCourseObject.semester || yearSemester === regCourseObject.semester || nextSemester === regCourseObject.semester">
                    <!-- <v-menu rounded="md" offset-y v-if="false"> -->

                        <template v-slot:activator="{ attrs, on }">
                            <v-btn color="#F6F2FF" class="white--text ml-4 mt-4" elevation="1" width="82" v-bind="attrs" v-on="on" id="btn-create" :disabled="currentSemester !== regCourseObject.semester && nextSemester !== regCourseObject.semester && yearSemester !== regCourseObject.semester">
                                <v-icon color="#745CA8">mdi-plus</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item v-for="item in itemsCreate" :key="item.module" link :disabled="item.disabled" @click="clickListitem(item.module)">
                                <v-list-item-title v-text="item.module"></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>

                <v-dialog v-model="dialogs.msteams" max-width="600">
                    <v-card class="text-center">
                        <v-card-title>
                            <span>MS Teams List</span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialogs.msteams = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>

                        <v-card-text class="pt-2">
                            <!-- <v-tooltip bottom close-delay="2000" color="#333"> -->
                            <div v-for="(items,i) in regCourseObject.mapping.msteams" :key="i">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn :key="i" color="#745CA8" dark outlined v-bind="attrs" v-on="on" id="tooltip-target-1" class="mb-2" style="width: 500px" @click="checkTeamsStatus(regCourseObject._id,items._id)">
                                            {{`${items.title} (${sectionAfterTitleName(items.sections)})`}} <i class="fas fa-external-link-alt"></i>
                                        </v-btn>
                                    </template>
                                    <span>{{sectionNameList(items.sections)}}</span>
                                </v-tooltip>
                            </div>
                            <v-btn color="#745CA8" dark outlined id="tooltip-target-1" class="mb-2" style="width: 500px" @click="openCreateModal.msteams = true , dialogs.msteams = false" :disabled="currentSemester !== regCourseObject.semester && nextSemester !== regCourseObject.semester && yearSemester !== regCourseObject.semester ">
                                Create <i class="fas fa-plus"></i>
                            </v-btn>
                        </v-card-text>

                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogs.canvas" max-width="600">
                    <v-card class="text-center">
                        <v-card-title>
                            <span>Canvas List</span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialogs.canvas = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text class="pt-2">
                            <v-btn v-for="(items,i) in getOnlyCanvas" :key="i" color="#745CA8" dark outlined class="mb-2" style="width: 500px" @click="checkLmsStatus(regCourseObject._id,items._id)">
                                {{`${items.title} (${sectionAfterTitleName(items.sections)})`}} <i class="fas fa-external-link-alt"></i>
                            </v-btn>
                            <!-- <v-btn
                      color="#745CA8"
                      dark
                      outlined
                      id="tooltip-target-1"
                      class="mb-2"
                      style="width: 500px"
                      @click="openCreateModal.lms = true , dialogs.canvas = false"
                      :disabled="currentSemester !== regCourseObject.semester && nextSemester !== regCourseObject.semester && yearSemester !== regCourseObject.semester "
                    >
                      Create <i class="fas fa-plus"></i>
                    </v-btn> -->
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogs.exam" max-width="600">
                    <v-card class="text-center">
                        <v-card-title>
                            <span>Exam List</span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialogs.exam = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text class="pt-2">
                            <div v-for="(items,i) in getOnlyExam" :key="i">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn :key="i" color="#745CA8" dark outlined v-bind="attrs" v-on="on" class="mb-2" style="width: 500px" @click="checkLmsStatus(regCourseObject._id,items._id)">
                                            {{`${items.title} (${sectionAfterTitleName(items.sections)})`}} <i class="fas fa-external-link-alt"></i>
                                        </v-btn>
                                    </template>
                                    <span>{{sectionNameList(items.sections)}}</span>
                                </v-tooltip>
                            </div>
                            <v-btn color="#745CA8" dark outlined id="tooltip-target-1" class="mb-2" style="width: 500px" @click="openCreateModal.cmu_exam = true , dialogs.exam = false" :disabled="currentSemester !== regCourseObject.semester && nextSemester !== regCourseObject.semester && yearSemester !== regCourseObject.semester ">
                                Create <i class="fas fa-plus"></i>
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogs.moodle" max-width="600">
                    <v-card class="text-center">
                        <v-card-title>
                            <span>Kc-Moodle List</span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialogs.moodle = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text class="pt-2">
                            <v-btn v-for="(items,i) in getOnlyMoodle" :key="i" color="#745CA8" dark outlined class="mb-2" style="width: 500px" @click="checkLmsStatus(regCourseObject._id,items._id)">
                                {{`${items.title} (${sectionAfterTitleName(items.sections)})`}} <i class="fas fa-external-link-alt"></i>
                            </v-btn>
                            <v-btn color="#745CA8" dark outlined id="tooltip-target-1" class="mb-2" style="width: 500px" @click="openCreateModal.lms = true , dialogs.moodle = false" :disabled="currentSemester !== regCourseObject.semester && nextSemester !== regCourseObject.semester && yearSemester !== regCourseObject.semester ">
                                Create <i class="fas fa-plus"></i>
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogs.mango" max-width="600">
                    <v-card class="text-center">
                        <v-card-title>
                            <span>Mango List</span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialogs.mango = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text class="pt-2">
                            <div v-for="(items,i) in getOnlyMango" :key="i">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn :key="i" color="#745CA8" dark v-bind="attrs" v-on="on" outlined class="mb-2" style="width: 500px" @click="checkLmsStatus(regCourseObject._id,items._id)">
                                            {{`${items.title} (${sectionAfterTitleName(items.sections)})`}} <i class="fas fa-external-link-alt"></i>
                                        </v-btn>
                                    </template>
                                    <span>{{sectionNameList(items.sections)}}</span>
                                </v-tooltip>
                            </div>
                            <v-btn color="#745CA8" dark outlined id="tooltip-target-1" class="mb-2" style="width: 500px" @click="openCreateModal.lms = true , dialogs.mango = false" :disabled="currentSemester !== regCourseObject.semester && nextSemester !== regCourseObject.semester && yearSemester !== regCourseObject.semester ">
                                Create <i class="fas fa-plus"></i>
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-col>

        </v-row>

    </v-card-text>

    <v-spacer></v-spacer>
    <v-spacer></v-spacer>
    <v-spacer></v-spacer>
    <create-teams-modal :courseData="regCourseObject" @modalSubmit="onModalTeamsSubmit" :openModal="openCreateModal.msteams" @modalClose="openCreateModal.msteams = false" style="display: none" v-bind:key="`create-teams-modal-${refreshKey}`" />
    <create-lms-modal :termSelected="termSelected" :courseData="regCourseObject" :mangoGrant="mangoGrant" :openModal="openCreateModal.lms" @modalSubmit="onModalLmsSubmit" @modalClose="openCreateModal.lms = false" style="display: none" v-bind:key="`create-lms-modal-${regCourseObject._id}`" />
    <CreateCMUExamModal :courseData="regCourseObject" @modalSubmit="onModalCMUExamSubmit" :openModal="openCreateModal.cmu_exam" @modalClose="openCreateModal.cmu_exam = false" style="display: none" v-bind:key="`create-cmu-exam-modal-${regCourseObject._id}`" />
    <ModalNotifyInfo :showModal="showModal" :openWeb="openWeb" @onClose="clickInfoToggleClose" />
    <ModalNotifyAutoEnroll :showModal="showModalAutoEnroll" :textTitle="autoEnrollText" :textDetail="autoEnrollTextDetail" @onClose="clickAutoEnrollClose" @onConfirm="handleAutoEnroll(regCourseObject._id ,syncStatus )" />
    <!-- <div v-if="this.currentSemester === regCourseObject.semester || regCourseObject.semester === yearSemester || regCourseObject.semester === '466'"> -->

    <div v-if="false">
            <v-card-actions>
            <template v-if="checkServiceEmpty">
                <v-row class="align-items-end">
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <div @click="clickInfoToggle">
                        <v-icon size="18px" style="margin-top: 1.7rem;">
                            mdi-help-circle
                        </v-icon>
                    </div>
                    <v-col style="padding-bottom: 25px; !important" class="align-center justify-content-center">
                        <div class="text-center" style="font-size: 10px; margin-right: 17px;">Auto Add/Drop</div>
                        <div class="text-center tri-state-toggle" @click="clickAutoEnroll">

                            <button :class="syncStatus ==='no'? 'tri-state-toggle-button-no active' : 'tri-state-toggle-button-no'" id="toggle-button3">
                            </button>

                            <button :class="syncStatus ===''? 'tri-state-toggle-button-middle active' : 'tri-state-toggle-button-middle'" id="toggle-button2">
                            </button>

                            <button :class="syncStatus ==='yes'? 'tri-state-toggle-button active' : 'tri-state-toggle-button'" id="toggle-button1">
                            </button>
                        </div>
                    </v-col>
                </v-row>
            </template>
        </v-card-actions>
    </div>

</v-card>
</template>

<script>
import 'vuetify/dist/vuetify.min.css'
import CreateTeamsModal from './MsTeams/CreateTeamsModal'
import CreateLmsModal from './CreateLmsModal'
import CreateCMUExamModal from './CMUExam/CreateCMUExamModal'
import ModalNotifyInfo from './ModalNotification/ModalNotifyInfo.vue'
import ModalNotifyAutoEnroll from './ModalNotification/ModalNotifyAutoEnroll.vue'
import Swal from 'sweetalert2'
const config = require(`@/config`)


export default {
    name: 'CourseCard',
    components: {
        CreateTeamsModal,
        CreateLmsModal,
        CreateCMUExamModal,
        ModalNotifyInfo,
        ModalNotifyAutoEnroll
    },
    myOptions: {
        items: {
            preSelected: 'unknown',
            labels: [{
                    name: 'Off',
                    color: 'white',
                    backgroundColor: 'red'
                },
                {
                    name: 'unknown',
                    color: 'white',
                    backgroundColor: 'grey'
                },
                {
                    name: 'On',
                    color: 'white',
                    backgroundColor: 'green'
                }
            ]
        }
    },
    props: {
        termSelected: String,
        regCourseObject: Object,
        mangoGrant: Boolean
    },
    created() {
        this.checkToggleStatus(this.regCourseObject)
        this.regCourseObject.services = this.regCourseObject.services.sort(this.compare)
        
    },
    data: () => ({
        
        value1: null,
        checkServiceEmpty: true,
        currentSemester: config.lmsConfig.currentSemester,
        dialogs: {
            msteams: false,
            canvas: false,
            moodle: false,
            mango: false,
            exam: false
        },
        loadingLists: {
            msteams: false,
            canvas: false,
            moodle: false,
            mango: false,
            exam: false
        },
        loadingTeamsList: false,
        loadingLmsList: false,
        loadingCMUExam: false,
        showModal: false,
        showModalAutoEnroll: false,
        openWeb: 'https://docs.tlic.cmu.ac.th/course-portal-management/student-management#auto-enroll',
        itemsCourse: ['Course 1', 'Course 2'],
        syncStatus: "yes",
        autoEnrollText: '',
        autoEnrollTextDetail: '',
        btns: [
            ['Large', 'lg'],
        ],
        refreshKey: 0,
        colors: ['deep-purple accent-4', 'error', 'teal darken-1'],
        items: [...Array(4)].map((_, i) => `Item ${i}`),
        itemsCreate: [{
                "module": "Mango CMU",
                "disabled": false
            },
            {
                "module": "CMU Exam",
                "disabled": false
            },
            {
                "module": "Exam Manager",
                "disabled": true
            },
            {
                "module": "Grade Book",
                "disabled": true
            }
        ],
        itemsSetting: [{
                "module": "DASHBOARD",
                "disabled": false
            },
            // {
            //   "module": "SYNC STUDENTS",
            //   "disabled": false
            // }
        ],
        openCreateModal: {
            msteams: false,
            lms: false,
            cmu_exam: false
        }
    }),
    watch: {
        regCourseObject(val) {
            this.checkToggleStatus(val)
        },
    },
    computed: {
        getOnlyCanvas: function () {
            return this.regCourseObject.mapping.lms.filter(i => i.service === 'canvas')
        },
        getOnlyMoodle: function () {
            // let dataObj = this.regCourseObject.mapping.lms.filter(i => i.service === 'moodle')
            // dataObj.sort((a,b) => a.sec)
            // console.log("dataObj" , dataObj)
            return this.regCourseObject.mapping.lms.filter(i => i.service === 'moodle')
        },
        getOnlyMango: function () {
            return this.regCourseObject.mapping.lms.filter(i => i.service === 'mango')
        },
        getOnlyExam: function () {
            // console.log("list exam:<" , this.regCourseObject)
            return this.regCourseObject.mapping.lms.filter(i => i.service === 'exam')
        },
        currentSemester() {
            return this.$store.state.currentSemester
        },
        nextSemester() {
            return this.$store.state.nextSemester
        },
        yearSemester() {
            return this.$store.state.yearlySemester
        }

    },
    methods: {
        clickInfoToggle() {
            this.showModal = true;
        },
        clickAutoEnroll() {
            console.log("click", this.syncStatus)
            if (this.syncStatus === "yes") {
                this.showModalAutoEnroll = true;
                this.syncStatus = "no"
                this.autoEnrollText = "ปิดการเพิ่มและลบ"
                this.autoEnrollTextDetail = "รายชื่อนักศึกษาโดยอัตโนมัติ"
            } else if (this.syncStatus === "no") {
                this.showModalAutoEnroll = true;
                this.syncStatus = "yes"
                this.autoEnrollText = "เปิดการเพิ่มและลบ"
                this.autoEnrollTextDetail = "รายชื่อนักศึกษาโดยอัตโนมัติ"
            }

        },
        clickInfoToggleClose() {
            this.showModal = false;
        },
        clickAutoEnrollClose() {
            this.showModalAutoEnroll = false;
            this.checkToggleStatus(this.regCourseObject)

        },
        checkToggleStatus(courseObj) {
            this.syncStatus = 'yes';
            // console.log("courseObj" , courseObj)
            if (courseObj.mapping) {
                let count_service = 0;
                for (const service in courseObj.mapping) {
                    // console.log("service:>" , service)
                    let count_sync = 0;
                    for (let i = 0; i < courseObj.mapping[service].length; i++) {
                        // console.log(service,"courseObj.mapping[service][i].syncEnable :>" , courseObj.mapping[service][i].syncEnable )
                        if (courseObj.mapping[service][i].syncEnable === false) {
                            this.syncStatus = '';
                            count_sync = count_sync + 1;
                        }
                    }
                    if (count_sync >= courseObj.mapping[service].length) {
                        count_service = count_service + 1;
                    }
                }
                // console.log("count:>",Object.keys(courseObj.mapping).length , count_service)
                if (count_service >= Object.keys(courseObj.mapping).length) {
                    this.syncStatus = 'no';
                }
                // console.log("service :>" , courseObj.mapping["lms"].length === 0)
                if (courseObj.mapping["lms"].length === 0 && courseObj.mapping["msteams"].length === 0) {
                    this.checkServiceEmpty = false;
                }
                // console.log("courseObj sync status:>" , courseObj.title , ":",this.syncStatus)
            }
        },
        compare( a, b ) {
            if ( a.service < b.service ){
                return -1;
            }
            if ( a.service > b.service ){
                return 1;
            }
        return 0;
        },

        clickState(courseId, val) {

            this.onToggleSync(courseId, String(val))
        },
        goToCourse(courseId) {
            this.$router.push(`/courses/${courseId}`)
        },
        getServiceImage(service) {
            console.log(service)
            let images = ""
            switch (service) {
                case "msteams":
                    images = require.context('../assets/images', false, /\.svg$/)
                    return images('./' + 'microsoft-teams-logo-crop' + ".svg")
                case "canvas":
                    images = require.context('../assets/images', false, /\.png$/)
                    return images('./' + 'canvas-logo-crop' + ".png")
                case "moodle":
                    images = require.context('../assets/images', false, /\.png$/)
                    return images('./' + 'moodle-logo-crop' + ".png")
                case "mango":
                    images = require.context('../assets/images', false, /\.png$/)
                    return images('./' + 'mango-logo-crop' + ".png")
                case "exam":
                    images = require.context('../assets/images', false, /\.png$/)
                    return images('./' + 'exam-logo' + ".png")
                default:
                    break;
            }
            // return "../assets/images/canvas-logo-crop.png"
            // let images = require.context('../assets/images', false, /\.svg$/)
            // return images('./' + 'canvas-logo-crop' + ".png")
            // return images('./' + 'microsoft-teams-logo-crop' + ".svg")
        },
        onModalTeamsSubmit(val, typeCreate) {
            this.refreshKey += 1;
            // console.log('test click create team' , val)
            this.$emit('createTeamsSubmit', val, this.regCourseObject._id, typeCreate)
            this.checkToggleStatus(this.regCourseObject)
        },
        onModalLmsSubmit(val, selectOption) {
            // console.log("Submit :>", val  ,selectOption.service , selectOption.option)
            this.$emit('createLmsSubmit', val, this.regCourseObject._id, selectOption)
            this.checkToggleStatus(this.regCourseObject)
        },
        onModalCMUExamSubmit(val, typeCreate) {
            // onCreateCMUExam
            // console.log("createCMUExamSubmit" , val, this.regCourseObject._id  , typeCreate)
            this.$emit('createCMUExamSubmit', val, this.regCourseObject._id, typeCreate)
            this.checkToggleStatus(this.regCourseObject)

        },
        clickListitem(val) {
            // console.log("clickListitem:",val)
            switch (val) {
                case 'MS Teams':
                    this.openCreateModal.msteams = true
                    break
                case 'Mango CMU':
                    this.openCreateModal.lms = true
                    break
                default:
                    'CMU Exam'
                    this.openCreateModal.cmu_exam = true
                    break
            }
        },
        handleAutoEnroll(courseId, val) {
            // console.log("handle Auto Add/Drop:" , courseId , val)
            if (val === "yes") {

                let statusSync = true
                this.$emit('updateSync', courseId, statusSync)

            } else {
                let statusSync = false
                this.$emit('updateSync', courseId, statusSync)
            }
            this.showModalAutoEnroll = false;
            // this.clickAutoEnrollClose()
            // this.checkToggleStatus(this.regCourseObject)
        },
        onToggleSync(courseId, val) {
            if (val === "yes") {
                Swal.fire({
                    icon: 'info',
                    title: 'ท่านต้องการเปิดการซิงค์รายชื่ออัตโนมัติ',
                    html: '',
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'ตกลง',
                    cancelButtonText: 'ยกเลิก',
                }).then((result) => {
                    if (result.isConfirmed) {
                        let statusSync = val === "yes" ? true : false
                        this.$emit('updateSync', courseId, statusSync)
                        this.syncStatus = "yes"
                    }
                })
            } else {
                Swal.fire({
                    icon: 'info',
                    title: 'ท่านต้องการปิดการซิงค์รายชื่ออัตโนมัติ',
                    html: '',
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'ตกลง',
                    cancelButtonText: 'ยกเลิก',
                }).then((result) => {
                    if (result.isConfirmed) {
                        // updateSync
                        let statusSync = val === "yes" ? true : false
                        this.$emit('updateSync', courseId, statusSync)
                        this.syncStatus = "no"
                    }
                })
            }

        },

        clickShowListItems(service, id) {
            console.log(service)
            switch (service) {
                case "msteams":
                    this.clickGoToTeams(id)
                    break
                case "canvas":
                    this.clickGoToCanvas(id)
                    break
                case "moodle":
                    this.clickGoToMoodle(id)
                    break
                case "mango":
                    this.clickGoToMango(id)
                    break
                case "exam":
                    this.clickGoToExam(id)
                    break
                default:
                    break
            }
        },
        clickGoToTeams(id) {
            this.loadingLists.msteams = true
            this.dialogs.msteams = true
            console.log("clickGoToTeams", id)
        },
        clickGoToCanvas(id) {
            this.loadingLists.canvas = true
            this.dialogs.canvas = true
            console.log("clickGoToCanvas", id)
        },

        clickGoToExam(id) {
            this.loadingLists.exam = true
            this.dialogs.exam = true
            console.log("clickGoToExam", id)
        },
        clickGoToMoodle(id) {
            this.loadingLists.moodle = true
            this.dialogs.moodle = true
            console.log("clickGoToMoodle", id)
        },
        clickGoToMango(id) {
            this.loadingLists.mango = true
            this.dialogs.mango = true
            console.log("clickGoToMango", id)
        },
        checkTeamsStatus(courseId, mappingId) {
            console.log("checkTeamsStatus:", courseId, mappingId)
            this.$emit('checkTeamJob', courseId, mappingId)
        },
        checkLmsStatus(courseId, mappingId) {
            console.log("checkLmsStatus:", courseId, mappingId)
            this.$emit('checkLmsJob', courseId, mappingId)
        },
        clickListItemSetting(val, id) {
            switch (val) {
                case 'DASHBOARD':
                    this.goToCourse(id)
                    break
                default:
                    this.goToCourse(id)
                    break
            }
        },
        sectionAfterTitleName(sectionArr) {
            // console.log("section", sectionArr)
            for (let i = 0; i < sectionArr.length; i++) {
                if (i > 2) {
                    return sectionArr.slice(0, 3) + ",..."
                } else {
                    sectionArr[i] = this.filterSection(sectionArr[i])
                }

            }
            return sectionArr
        },
        sectionNameList(sectionArr) {
            // console.log("section", sectionArr)
            for (let i = 0; i < sectionArr.length; i++) {
                sectionArr[i] = this.filterSection(sectionArr[i])
            }
            return sectionArr
        },
        filterSection(section) {
            // console.log("filter section:",section)
            let sectionLec = section.substring(0, 3)
            let sectionLab = section.substring(3)
            if (sectionLec === '000' && sectionLab !== '000') {
                return `Lab.${sectionLab}`
            } else if (sectionLec !== '000' && sectionLab === '000') {
                return `Lec.${sectionLec}`
            }
            return section
        },
    },
}
</script>
